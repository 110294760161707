import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Icon, TaskHeaderIcon} from "../../dashboard/Dashboard";
import styled from "styled-components";
import IconReports from "../../resources/icons/ic-statistics.svg";
import Select from "react-select";
import '../../../src/components/dropdown/dropdown.scss';
import '../reports.scss';
import '../../../src/components/field/datepicker/datepicker.scss'
import {
    cultured,
    flame,
    greyLight,
    majorelleLight,
    maximumYellowLight,
    oceanGreenLight,
    vividSky,
    vividSkyLight
} from "../../Mixins";
import CircleChart from "../../components/charts/circle-chart/CircleChart";
import IconShare from "../../../src/resources/icons/ic-share.svg"
import {ExpandableList} from "../../organisation/ExpandableList";
import {useForm} from "react-hook-form";
import {Form, FormField, Label} from "../../components/field/FormField";
import {data} from '../../../src/util/api/mock';

function ReportsDashboard() {

    const [currentInstitute, setCurrentInstitute] = useState(data[0].id)
    let selectedOption = (data ?? []).find((option) => {
        return option.id === currentInstitute
    });

    const instituteData = data.find((item) => item.id === currentInstitute)

    const handleSelectionChange = (value) => {
        setCurrentInstitute(value)
    }

    return (
        <>
            <ReportsHeader
                selectedOption={selectedOption}
                currentInstitute={currentInstitute}
                onChange={handleSelectionChange}
            />
            <ReportsStatistics
                data={instituteData}
            />
            <ReportsDownloads
                data={instituteData}
            />
            <ReportsOrganogram
                data={instituteData}
            />
        </>
    )
}

const ReportsHeader = ({...props}) => {
    const {t} = useTranslation();
    const {register, handleSubmit, errors, setValue, getValues, trigger} = useForm();

    const Header = styled.div` 
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        div:nth-child(1){
            margin-bottom: 0 !important; 
        }
    `
    const HeaderFilters = styled.div`
        display: flex;
        gap: 10px;
        align-items: center;
        position: relative;
        bottom: 10px;
    `

    const ColumnFlex = styled.div`
        display: flex;
        flex-direction: column;
        gap: 5px;
        
        label {
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
        }
        
        ${FormField}{
            height: 50px !important;
        }

        &:nth-of-type(n+2) label {
            position: relative;
            top: 18px;
        }
    `

    const getOptions = () => {
        return data.map(option => {
            option.label = t('language.current_code') === 'nl' ? option.labelNL : option.labelEN;
            return option;
        })
    }

    return (
        <Header>
            <div className={"reports-title-row"}>
                <TaskHeaderIcon width={"82px"} height={"82px"}>
                    <Icon src={IconReports} width={"26px"}/>
                </TaskHeaderIcon>
                <h1>{t("report.tabs.dashboard.title")}</h1>
            </div>
            <HeaderFilters>
                <ColumnFlex>
                    <Label text={t("report.filters.organisation")} />
                    <Select
                        key={props.currentInstitute}
                        defaultValue={props.selectedOption}
                        getOptionValue={ option => t('language.current_code') === 'nl' ? option.labelNL : option.labelEN}
                        options={getOptions()}
                        className={"surf-dropdown"}
                        classNamePrefix={"surf-select"}
                        formatOptionLabel={option => (
                            <div className={"align-center"}>
                                <span style={{fontSize: "12px"}}>{t('language.current_code') === 'nl' ? option.labelNL : option.labelEN}</span>
                            </div>
                        )}
                        onChange={
                            (selection) => {
                                if (selection) {
                                    props.onChange(selection.id)
                                } else {
                                    props.onChange(null)
                                }
                            }
                        }
                    />
                </ColumnFlex>

                 <ColumnFlex>
                     <Label className={"add-margin"} text={t("report.filters.from")} />
                     <FormField key={"dateFrom"}
                                isRequired={false}
                                type={"datepicker"}
                                error={errors["dateFrom"]}
                                name={"dateFrom"}
                                register={register}
                                setValue={setValue}
                     />
                 </ColumnFlex>

                <ColumnFlex>
                    <Label className={"add-margin"} text={t("report.filters.until")} />
                    <FormField key={"dateUntil"}
                               isRequired={false}
                               type={"datepicker"}
                               error={errors["dateUntil"]}
                               name={"dateUntil"}
                               register={register}
                               setValue={setValue}
                    />
                </ColumnFlex>

            </HeaderFilters>
        </Header>
    )
}

const ReportsStatistics = ({...props}) => {
    const {t} = useTranslation();

    const Container = styled.section`
        margin-top: 50px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 40px;

        @media (max-width: 1000px) {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-row-gap: 20px;

            ${Item},
            ${GraphItem} {
                grid-column: span full;
            }
        }
    `;

    return (
        <Container>
            <Item data={props.data.statistics.submittedPublications} subtitle={t("report.imported_publications")} />
            <Item data={props.data.statistics.downloads} subtitle={t("report.downloads")} />
            <Item data={props.data.statistics.activeUsers} subtitle={t("report.active_users")} />
            <GraphItem data={props.data.statistics.publicationTypes} colors={[flame, vividSky, maximumYellowLight]} title={t("report.type_publications")} subtitle={t('language.current_code') === 'nl' ? props.data.labelNL : props.data.labelEN}></GraphItem>
            <GraphItem data={props.data.statistics.publicationStatuses} colors={[oceanGreenLight, majorelleLight, vividSkyLight, maximumYellowLight, cultured]} title={t("report.status_publications")} subtitle={t('language.current_code') === 'nl' ? props.data.labelNL : props.data.labelEN}></GraphItem>
        </Container>
    )
}

export const Item = ({...props}) => {

    const formattedNumber = new Intl.NumberFormat('de-DE').format(props.data);

    const ItemContainer = styled.div`
        display: flex;
        flex-direction: column;
        padding: 25px 35px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        grid-column: span 2;
        height: 120px;
        background: white;
        border-radius: 2px 20px 20px 20px;
        
        h3 {
            font-weight: 700;
        }

        hr {
            border: solid 0.5px ${greyLight};
            width: 100%;
        }
        
        p {
            font-size: 12px;
            margin: 0;
        }
    `

    return (
        <ItemContainer>
            <h3>{formattedNumber}</h3>
            <hr/>
            <p>{props.subtitle}</p>
        </ItemContainer>
    )
}

export const GraphItem = ({data = [], ...props}) => {

    const {t} = useTranslation()

    const Container = styled.div`
        display: flex;
        flex-direction: column;
        padding: 28px;
        gap: 6px;
        grid-column: span 3;
        height: 430px;
        background: white;
        border-radius: 2px 20px 20px 20px;
    `

    const ChartContainer = styled.div`
        width: 100%;
        display: flex;
        gap: 20px;
        align-items: center;
    `

    const ChartNumbers = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        font-size: 12px;
        left: 5px;
        bottom: 170px;
    `

    const LegendContainer = styled.div`
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-bottom: 50px;
        
        div {
            font-size: 12px;
            display: flex;
            gap: 5px;
            align-items: center;
            word-break: break-all;
        }
    `

    const LegendBox = styled.div`
        width: 15px; height: 15px; border-radius: 2px 5px 5px 5px;
        background-color: ${props => props.fill && props.fill};
    `

    const totalValue = data.reduce((accumulator, item) => {
        return accumulator + item.value;
    }, 0);

    const formattedNumber = new Intl.NumberFormat('de-DE').format(totalValue);

    return (
        <Container>
            <div>
                <h2>{props.title}</h2>
                <h5>{props.subtitle}</h5>
            </div>
            <ChartContainer>
                <div>
                    <CircleChart data={data} colors={props.colors} width={300} height={300}/>
                    <ChartNumbers>
                        <h3>{formattedNumber}</h3>
                        <span>{t("report.report_publications")}</span>
                    </ChartNumbers>
                </div>
                <LegendContainer>
                    {data.map((entry, index) => (
                        <div>
                            <LegendBox fill={props.colors[index]} />
                            {t('language.current_code') === 'nl' ? entry.labelNL : entry.labelEN}
                        </div>
                    ))}
                </LegendContainer>
            </ChartContainer>
        </Container>
    )
}

const ReportsDownloads = ({...props}) => {

    const {t} = useTranslation()

    const Container = styled.div`
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 15px;
    `

    const TableHeaders = styled.div`
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
    `

    const LeftSide = styled.div`
        display: flex;
        align-items: center;
        font-family: 'Open Sans', sans-serif;
        
        img {
            padding-left: 20px;
        } 
        
        span {
            font-size: 14px;
            font-weight: 600;
            padding-left: 15px;
        }
        
        div:nth-of-type(2){
            width: 150px;
            padding-left: 50px;
        }
    `

    const RightSide = styled.div`
        display: flex;
        width: 300px;
        padding-left: 15px;
        gap: 50px;
        font-size: 12px;
        font-weight: 700;
        font-family: 'Open Sans', sans-serif;
        
        span:nth-of-type(1){
            width: 70px;
        }

        span:nth-of-type(2){
            width: 150px;
        }
    `

    const TableRow = styled.div`
        background-color: white;
        height: 50px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    `

    return (
        <Container>
            <h2>{t("report.downloads_per_channel")}</h2>
            <TableHeaders>
                <LeftSide>
                    <div></div>
                    <div>{t("report.channel")}</div>
                </LeftSide>
                <RightSide>
                    <span></span>
                    <span>{t("report.total")}</span>
                </RightSide>
            </TableHeaders>
            {props.data.statistics.downloadsPerChannel.map((item) => {
                const formattedNumber = new Intl.NumberFormat('de-DE').format(item.total);
                return (
                    <TableRow>
                        <LeftSide>
                            <img src={IconShare}/>
                            <span>{item.channel}</span>
                        </LeftSide>
                        <RightSide>
                            <span></span>
                            <span>{formattedNumber}</span>
                        </RightSide>
                    </TableRow>
                )
            })}
        </Container>
    )
}

const ReportsOrganogram = ({...props}) => {


    const Container = styled.div`
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
        margin-top: 50px;
    `

    const {t} = useTranslation()

    return (
        <Container>
            <h2>{t("report.total_publications_per_department")}</h2>
            <ExpandableList data={props.data.institutes}
                            showReportsData={true}
                            includeHeaders={[t("report.total")]}
            />
        </Container>
    )
}

export default ReportsDashboard